<template>
  <PersonalLanding />
</template>

<script>
import PersonalLanding from './components/PersonalLanding.vue'

export default {
  name: 'App',
  components: {
    PersonalLanding
  }
}
</script>